<template>
	<div class="main_wrap _la">
		<div class="language_wrap">
            <section class="_language">
                <h5 class="_lan">언어설정</h5>
                <a
                    href="language_kor" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'kor')" 
                    id="kor"
                >
                    kor
                </a>
                <a 
                    href="language_nep" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'nep')" 
                    id="nep"
                >
                    nep
                </a>
                <a 
                    href="language_rus" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'rus')" 
                    id="rus"
                >
                    rus
                </a>
                <a 
                    href="language_eng" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'eng')" 
                    id="eng"
                >
                    eng
                </a>
                <a 
                    href="language_tai" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'tai')" 
                    id="tai"
                >
                    tai
                </a>
                <a 
                    href="language_mog" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'mog')" 
                    id="mog"
                >
                    mog
                </a>
                <a 
                    href="language_kaz" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'kaz')" 
                    id="kaz"
                >
                    kaz
                </a>
                <a 
                    href="language_bur" 
                    class="_lan" 
                    v-on:click="changeLang($event, 'bur')" 
                    id="bur"
                >
                    bur
                </a>
            </section>
        </div>
	</div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	name:"Map",
	props: {
	},
	components:{
		
	},
	computed:{
	},
	methods: {
		changeLang(e, _lang) {
			e.preventDefault();
            localStorage.setItem("lang", _lang);
            this.$store.commit("readyCahngeLang",_lang);
            this.$router.push('/');
		}
	},
	data: () => ({
	}),
	mounted() {
	},
	created(){
		let vm = this;
		vm.lang = vm.$store.state.lang;
	},
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.language[lang],
			type: 'website',
			desc: '하이바이블의 언어설정페이지입니다. 한국어, 영어, 태국어, 러시아어, 몽골어, 네팔어, 카자흐어, 미얀마어를 지원합니다.',
			keyword: '하이바이블, hibible, 언어설정, 한국어, 영어, 태국어, 러시아어, 몽골어, 네팔어, Language setting, язык, भाषा सेटि, भाषा सेटिङहरू, ภาษา, ตั้งค่าภาษา, Хэлний тохиргоо, қазақ, тіл параметрлері, မြန်မာ, ဘာသာစကား ဆက်တင်များ',
			thumb: '/hibible/resource/images/lan_bg_d.svg',
			// link:'/hibible',
		});
	}
}
</script>